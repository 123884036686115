import React from "react";
import useScript from "./hooks/useScript";

const NBADynasty = () => {
    useScript("https://cdn.fantasypros.com/js/fp-widget-2.0.js")
    return ( <div
        id="fp-widget"
        data-height="100%"
        data-width="100%"
        data-thead_color="#964b00"
        data-thead_font="#522900"
        data-t_alt_row="#f0f0f0"
        data-link_color="#964b00"
        data-pill_color="#964b03"
        data-sport="NBA"
        data-wtype="DK"
        data-filters=""
        data-scoring="STD"
        data-expert="5825"
        data-affiliate_code=""
        data-year="2022"
        data-week="0"
        data-auction="false"
        data-Notes="false"
        data-tags="false"
        data-cards="true"
        data-showPodcastIcons="false"
        data-format="table"
        data-promo_link="false"
        data-title_header="false"
        data-positions="ALL:PG:SG:SF:PF:G:F:C"
        data-ppr_positions=""
        data-half_positions=""
        data-site=""
        data-fd_aff=""
        data-dk_aff=""
        data-fa_aff=""
        data-dp_aff="">
    </div>
    )
};

export default NBADynasty;
  