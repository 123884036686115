import React from "react";
import { BigGradientText, CenterText } from "../styles";

const NFLWeekly = () => {
  return (
    <CenterText>
      <BigGradientText>Week One Rankings 
      <br></br>
      Coming Soon</BigGradientText>
    </CenterText>
  );
};

export default NFLWeekly;
  