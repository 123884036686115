import React from "react";
import { BigGradientText, CenterText } from "../styles";

const Home = () => {
  return (
    <CenterText>
      <BigGradientText>Welcome to 
      <br></br>
      Laying The Wood</BigGradientText>
    </CenterText>
  );
};

export default Home;