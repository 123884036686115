import React from "react";
import { BigGradientText, CenterText } from "../styles";


const NBARestofSeason = () => {
  return (
    <CenterText>
      <BigGradientText>NBA Rankings 
      <br></br>
      Coming Soon</BigGradientText>
    </CenterText>
  );
};

export default NBARestofSeason;
  